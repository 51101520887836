import { initLoadMoreBooks } from './components/initLoadMoreBooks';
import { initSearch } from './components/initSearch';
import { initToggleSearch } from './components/initToggleSearch';
import { initPaymentPopup } from './components/initPaymentPopup';
import { initScrollLocker } from './assets/scrollLocker';
import { initScrollToAnchor } from './components/initScrollToAnchor';
import { initAboutSlider } from './components/initAboutSlider';
import { initMobileMenu } from './components/initMobileMenu';
import { initScrollAnimation } from './components/initScrollAnimation';

window.addEventListener('load', handleOnLoadPage, false);

function handleOnLoadPage() {
    initLoadMoreBooks();
    initSearch();
    initToggleSearch();
    initPaymentPopup();
    initScrollLocker();
    initScrollToAnchor();
    initAboutSlider();
    initMobileMenu();
    initScrollAnimation();
}
